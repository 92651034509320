import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import IconsSvg from "../../Global/IconsSvg";

class СhoiceBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: this.props.disabled,
    };
  }

  render() {
    return (
      <div
        className={`col-xs-12 col-sm-6 col-md-3 col-xl-3 choice-list hello-page__list-item ${
          this.state.disabled ? "choice-list--disabled" : ""
        }`}
      >
        <div className="choice-list__wrapper">
          <IconsSvg image={this.props.image} className="choice-list__img" />
          <div className="choice-list__title">{this.props.title}</div>
          <div className="choice-list__text">{this.props.text}</div>
          <Link
            to={this.props.link ? this.props.link : "#"}
            className="btn btn--standart choice-list__btn"
            disabled={this.state.disabled ? "disabled" : ""}
          >
            {this.state.disabled
              ? this.props.disabledText
                ? this.props.disabledText
                : this.props.buttonText
              : this.props.buttonText}
            <IconsSvg image="arrow" className="arrow-icon arrow-icon--left" />
          </Link>
        </div>
      </div>
    );
  }
}

export default СhoiceBlock;
