import "./formik-error-message.scss";

import cn from "classnames";
import { ErrorMessage } from "formik";
import React from "react";

const FormikErrorMessage = ({ name, className }) => (
  <ErrorMessage
    name={name}
    component="div"
    className={cn(className, "errorMessage")}
  />
);

FormikErrorMessage.defaultProps = {
  className: "",
};

export default FormikErrorMessage;

