import React from "react";
import { Social } from "./Social";
import vkImage from "../../img/socials/vk-min.png";
import fbImage from "../../img/socials/fb-min.png";
import igImage from "../../img/socials/ig-min.png";
import okImage from "../../img/socials/telegram.svg";
import ytImage from "../../img/socials/yt-min.svg";

const socials = [
  ["https://vk.com/omegaed", vkImage, "vk"],
  ["https://t.me/omega_future", okImage, "ok"],
  ["https://www.youtube.com/channel/UCOaEsDX0tHKLnMF_SaSGr5A", ytImage, "youtube"],
];

export const SocialPanel = () => (
  <div className="social-panel">
    {socials.map(([linkTo, imageUrl, name]) => <Social key={name} {...{ linkTo, imageUrl, name }} />)}
  </div>
);
