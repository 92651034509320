import React from "react";
import { Link } from "react-router-dom";

const Menu = props => (
  <div className={`menu ${props.menuClass && props.menuClass}`}>
    {props.menu.map((el, idx) =>
      <Link
        key={idx}
        to={el.link}
        className={`link ${props.linkClass && props.linkClass}`}
        onClick={el.function}
        onMouseEnter={el.onHover}
      >
        {el.icon && <i className="sidebar__ico material-icons">{el.icon}</i>}
        {el.text}
      </Link>)}
  </div>
);

export default Menu;
