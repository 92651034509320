import React from "react";
import { Link } from "react-router-dom";
import { SocialPanel } from "../Main/Social_panel";
import logoFasie from "../../img/logo-fasie.png";
import pointGrowth from "../../img/rise-point.svg";
import omegaEdu from "../../img/omega-edu.svg";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row footer__row">
            <div className="footer__column">
              <span className="footer__title" />
              <p className="footer__text main_text">
                ООО {"\""}
                Школьный навигатор
                {"\""}
              </p>
              <p className="footer__text main_text">
                191023, г. Санкт-Петербург,
                <br />
                ул. Гороховая, д.30 литера А,
                <br />
                помещение 1Н, офис 8
              </p>
              <p className="footer__text main_text">
                Для связи воспользуйтесь
                <br />
                <Link
                  className="footer__link link link--inline"
                  to="/register/support/"
                >
                  формой
                </Link>{" "}
                обратной связи.
              </p>
            </div>
            <div className="footer__column justify-between">
              <SocialPanel />
              <div className="related-projects">
                <div className="justify-between items-center">
                  <p className="footer__text">
                    Подходит для
                  </p>
                  <a href="http://xn----7sbball2blrfcxoig8e.xn--p1ai/" target="_blank">
                    <img className="footer__image m-1" src={pointGrowth} />
                  </a>
                </div>
              </div>
              <div className="related-projects">
                <div className="justify-between items-center">
                  <span className="footer__text ">
                    Часть экоситемы
                  </span>
                  <a href="http://omega-ed.ru/" target="_blank">
                    <img className="footer__image m-3" src={omegaEdu} />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <p className="footer__text main_text">
                © Образовательный
                <br />
                навигатор школьника, 2022 г
              </p>
              <p className="footer__text main_text">
                Создан при поддержке
                <br />
                <a
                  className="footer__link link link--inline"
                  href="http://www.fasie.ru/"
                >
                  Фонда содействия развитию
                </a>
                <br />
                малых форм предприятий
                <br />в научно-технической сфере
                <br />
                по программе «Старт-1»
              </p>
              <div>
                <a href="http://fasie.ru/" rel="noopener noreferrer" target="_blank">
                  <img
                    className="footer__image"
                    src={logoFasie}
                    alt="Фонд содействия развитию"
                    title="Фонд содействия развитию"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
