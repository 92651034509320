export const Cabinet_Routing = role =>
  ({
    5: "/student/",
    6: "/school/",
    7: "/org/",
    8: "/group/",
  }[role] || "/register/step-one/");

export const getCaseEnding = (number, titles) => {
  return titles[
    number % 10 === 1 && number % 100 !== 11
      ? 0
      : number % 10 >= 2 &&
        number % 10 <= 4 &&
        (number % 100 < 10 || number % 100 >= 20)
      ? 1
      : 2
  ];
};

export const addSpace = val => {
  let number = val + "",
    numberString = "";

  let steps = (number.length / 3 + "").substr(0, 1);

  for (let i = 1; i <= steps; i++) {
    let toAdd = number.substr(-3 * i, 3);
    numberString = " " + toAdd;
  }

  numberString = number.substr(0, number.length - 3 * steps) + numberString;

  return numberString;
};

// если размер окна браузера менее передаваемого разрешения, вернёт правду
export function checkResolution(resolution) {
  return +window.innerWidth < +resolution;
}

// задаём в стэйтах объект с данными о типе устройства; передаём внутрь данные о желаемых разрешениях, которые будем проверять
export function setDeviceState(tabletResolution, mobileResolution) {
  let device = {};

  if (checkResolution(mobileResolution)) {
    device.desktop = false;
    device.tablet = false;
    device.mobile = true;
  } else if (checkResolution(tabletResolution)) {
    device.desktop = false;
    device.tablet = true;
    device.mobile = false;
  } else {
    device.desktop = true;
    device.tablet = false;
    device.mobile = false;
  }

  this.setState({ device });
}

export function getCabinetName() {
  return window.location.pathname.split("/")[1];
}

export const getRole = (rolesObject = {}) =>
  Number(Object.keys(rolesObject)[0]) || null;

export const getCharCodeRole = roleNumber =>
  ({
    5: "student",
    7: "org",
    8: "group",
  }[roleNumber] || "");

export const isEqualsRoles = (r1, r2) => Number(r1) === Number(r2);

export const notEqualsRoles = (r1, r2) => Number(r1) !== Number(r2);

// Проверка наличия цифры в массиве-периоде. Например, есть ли пятый класс в массиве [3,11] (3-11 класс)
export const checkNumberInInterval = (number, array) =>
  Array.from(
    { length: Number(array[1]) - Number(array[0]) + 1 },
    (x, i) => i + Number(array[0]),
  ).includes(Number(number));
