import React, { Component } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import logo from "../../img/logo.png";
import { setDeviceState, getRole, getCharCodeRole, isEqualsRoles } from "../Global/Global_functions";
import { authPreload, eventsPreload, groupsPreload, homePreload, newsPreload, instructionPreload } from "../Global/Loadables";
import Burger_Menu from "../Global/Menu/Burger_Menu";
import Menu from "../Global/Menu/Menu";
import User_Menu from "../Global/Menu/User_Menu";
import { deleteCookie } from "../Global/Cookie_function";

class Header extends Component {
  state = {
    data: {},
    activeUser: false,
    device: {
      desktop: false,
      tablet: false,
      mobile: false,
    },
  };

  _setDeviceState = setDeviceState.bind(this, 1050, 450);

  componentDidMount = () => {
    window.addEventListener("resize", this._setDeviceState);
    this.setData(this.props.data);
    this._setDeviceState();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this._setDeviceState);
  }

  componentDidUpdate = prevProps => {
    if (prevProps === this.props) return null;
    this.setData(this.props.data);
  }

  setData = data => {
    if (!Object.keys(data).length) return null;
    this.setState({ data, activeUser: true });
  }

  exit = () => {
    deleteCookie("token");
    this.props.history.push("/auth/");
  };

  constructMenu = role => {
    const { data, device } = this.state;

    const events = {
      link: "/events/",
        text: "Активности",
        icon: "",
        onHover: eventsPreload,
    };

    const groups = {
      link: "/groups/",
      text: "Сообщества",
      icon: "",
      onHover: groupsPreload,
    };

    const news = {
      link: "/news/",
      text: "Новости",
      icon: "",
      onHover: newsPreload,
    };

    const instruction = {
      link: "/instruction/",
      text: "Инструкция",
      icon: "",
      onHover: instructionPreload,
    };

    const personalCabinet = {
      link: `/${getCharCodeRole(role)}/`,
      text: "Личный кабинет",
      icon: "",
    };

    const editProfile = {
      link: `/${getCharCodeRole(role)}/edit-profile/`,
      text: "Изменить профиль",
      icon: "",
    };

    const mySchool = {
      link: `/groups/${data.character_code}`,
      text: "Моя школа",
      icon: "",
    };

    const login = {
      link: "/auth/",
      text: "Вход",
      icon: "",
    };

    const exit = {
      link: "#",
      text: "Выход",
      icon: "",
      function: this.exit,
    };

    const topMenu = [
      ...device.desktop ? [events] : [],
      ...device.desktop ? [groups] : [],
      ...device.desktop ? [news] : [],
      ...device.desktop ? [instruction] : [],
    ];

    const dropdownMenu = [
      ...isEqualsRoles(role, 7) ? [mySchool] : [],
      ...role ? [personalCabinet] : [],
      ...role ? [editProfile] : [],
      ...device.desktop ? [] : [events],
      ...device.desktop ? [] : [groups],
      ...device.desktop ? [] : [news],
      ...device.desktop ? [] : [instruction],
      ...!role && !device.desktop ? [login] : [],
      ...role ? [exit] : [],
    ];

    return { topMenu, dropdownMenu };
  }

  render() {
    const { data, activeUser } = this.state;

    const role = getRole(data.roles);

    const profileImage = {
      5: data.photo,
      7: data.logo ? data.logo.SRC : null,
      8: data.logo ? data.logo.SRC : null,
    }[role];
    const menu = this.constructMenu(role);

    return (
      <header className={`header ${cn(this.props.className)}`}>
        <div className="container">
          <div className="row">
            <Link to="/" className="logo header__logo" onMouseEnter={homePreload}>
              <img src={logo} alt="Навигатор школьника" />
            </Link>
            {Boolean(menu.topMenu.length) &&
              <Menu
                menu={menu.topMenu}
                history={this.props.history}
                menuClass="header__menu header__menu--center"
                linkClass="header__link"
              />
            }
            {activeUser
              ? <User_Menu
                  menu={menu.dropdownMenu}
                  photo={profileImage}
                  name={data.short_name || data.name}
                  history={this.props.history}
                  menuClass="header__menu header__menu--adaptive"
                  linkClass="header__link"
                />
              : menu.dropdownMenu.length
              ? <Burger_Menu
                  menu={menu.dropdownMenu}
                  history={this.props.history}
                  menuClass="header__menu header__menu--adaptive"
                  linkClass="header__link"
                />
              : <Link to="/auth/" className="link" onMouseEnter={authPreload}>
                  Вход
                </Link>
            }
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
