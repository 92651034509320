import React from "react";
import Loadable from "react-loadable";

import Loader from "./Loader";

function Loading() {
  return <div />;
}

export const Home = Loadable({
  loader: () => import("../Index/Home"),
  loading: Loader,
});

export const UserAgreement = Loadable({
  loader: () => import("../Index/UserAgreement"),
  loading: Loading,
});

export const Password_forgot = Loadable({
  loader: () => import("../Auth/Password_forgot"),
  loading: Loading,
});

export const Password_Reset = Loadable({
  loader: () => import("../Auth/Password_Reset"),
  loading: Loading,
});

export const Groups = Loadable({
  loader: () => import("../Groups/Groups"),
  loading: Loader,
});

export const News = Loadable({
  loader: () => import("../News/News"),
  loading: Loader,
});

export const Instruction = Loadable({
  loader: () => import("../Instruction/Instruction"),
  loading: Loader,
});

export const Not_found = Loadable({
  loader: () => import("../Global/Not_found"),
  loading: Loading,
});

export const Portfolio = Loadable({
  loader: () => import("../Portfolio/Portfolio"),
  loading: Loading,
});

export const Public_Portfolio = Loadable({
  loader: () => import("../Portfolio/Public_Portfolio"),
  loading: Loading,
});

export const Student_Cabinet = Loadable({
  loader: () => import("../Cabinets/Student/Student_Cabinet"),
  loading: Loading,
});

export const Groups_Cabinet = Loadable({
  loader: () => import("../Cabinets/Group/Groups_Cabinet"),
  loading: Loading,
});

export const Organize_Cabinet = Loadable({
  loader: () => import("../Cabinets/Organize/Organize_Cabinet"),
  loading: Loading,
});

export const Events = Loadable({
  loader: () => import("../Events/Events"),
  loading: Loader,
});

export const Auth = Loadable({
  loader: () => import("../Auth/Auth"),
  loading: Loader,
});

export const Registration = Loadable({
  loader: () => import("../Registration/Registration"),
  loading: Loading,
});

export const authPreload = () => {
  Auth.preload();
};

export const homePreload = () => {
  Home.preload();
};

export const eventsPreload = () => {
  Events.preload();
};

export const groupsPreload = () => {
  Groups.preload();
};

export const newsPreload = () => {
  News.preload();
};

export const instructionPreload = () => {
  Instruction.preload();
};
