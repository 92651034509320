import React from "react";
import ReactDOM from "react-dom";

import Menu from "./Menu";

class Burger_Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.active = this.active.bind(this);
    this._handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this._handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleClickOutside, true);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        active: false,
      });
    }
  }

  active() {
    this.setState({ active: !this.state.active });
  }

  render() {
    return (
      <div
        className={`burger-menu ${
          this.props.burgerMenuClass ? this.props.burgerMenuClass : ""
        }`}
        onClick={this.active}
      >
        <div
          className={`burger-menu__opener ${
            this.props.menuOpenerClass ? this.props.menuOpenerClass : ""
          } ${this.state.active ? "active" : ""}`}
        >
          <div />
          <div />
          <div />
        </div>
        <Menu
          menu={this.props.menu}
          menuClass={`${this.props.menuClass ? this.props.menuClass : ""} ${
            this.props.menuOpenerClass ? this.props.menuOpenerClass : ""
          } ${this.state.active ? "active" : ""}`}
          linkClass={this.props.linkClass}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default Burger_Menu;
