import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import IconsSvg from "../Global/IconsSvg";
import Section from "../Global/Section";
import СhoiceBlock from "./HelloPage/СhoiceBlock";
import File from "./File.pdf";
import pdfIcon from "../../img/pdf.png";
import set from "core-js/library/fn/reflect/set";


const student = [
  {
    image: "student_fill_profile",
    title: "Заполнить профиль",
    text: "Пользуйся рекомендательными возможностям и отслеживай только интересные для тебя мероприятия.",
    buttonText: "Заполнить",
    disabledText: "Заполнено",
    disabled: false,
    link: "/student/edit-profile/",
  },
  {
    image: "student_seach_activity",
    title: "Найти занятия",
    text: "Планируй время с пользой для себя. Участвуй в конференциях, олимпиадах, конкурсах, фестивалях и многих других мероприятиях.",
    buttonText: "Найти",
    disabledText: "",
    disabled: false,
    link: "/events/",
  },
  {
    image: "student_community",
    title: "Вступить в сообщества",
    text: "Находи интересные сообщества и следи за их обновлениями, получай свежую информацию о новых мероприятиях и активностях.",
    buttonText: "Вступить",
    disabledText: "",
    disabled: false,
    link: "/groups/",
  },
  {
    image: "student_progress",
    title: "Добавить достижения",
    text: "Добавь предыдущие достижения, ведь каждая активность идет в копилку твоего электронного портфолио. ",
    buttonText: "Добавить",
    disabledText: "",
    disabled: false,
    link: "/student/results/add/",
  },
];
const organize = [
  {
    image: "organizer_fill_profile",
    title: "Заполнить профиль",
    text: "Украсьте вашу страницу при помощи аватара, фона и фото. Не забудьте добавить официальный сайт вашей организации.",
    buttonText: "Заполнить",
    disabledText: "Заполнено",
    disabled: false,
    link: "/org/edit-profile/",
  },
  {
    image: "add_activity",
    title: "Добавить активность",
    text: "Планируйте и создавайте мероприятия. Отслеживайте активность обучающихся и их вовлеченность в образовательный процесс.",
    buttonText: "Добавить",
    disabledText: "Добавлено",
    disabled: false,
    link: "/org/events/create/",
  },
  {
    image: "organizer_community",
    title: "Создать сообщество",
    text: "Формируйте сообщества внутри вашей организации. Расскажите ученикам о внеклассных мероприятиях.",
    buttonText: "Создать",
    disabledText: "Создано",
    disabled: false,
    link: "/org/groups/create/",
  },
  {
    image: "organizer_add_students",
    title: "Добавить обучающихся",
    text: "Добавляйте обучающихся вручную или с помощью массового загрузчика. Это быстро и просто!",
    buttonText: "Добавить",
    disabledText: "Добавлено",
    disabled: false,
    link: "/org/import/",
  },
];
const community = [
  {
    image: "community_profile",
    title: "Заполнить профиль",
    text: "Украсьте ваше сообщество при помощи аватара, фона и фото. Не забудьте добавить официальный сайт вашего сообщества. ",
    buttonText: "Заполнить",
    disabledText: "Заполнено",
    disabled: false,
    link: "/group/edit-profile/",
  },
  {
    image: "add_activity",
    title: "Добавить активность",
    text: "Формируйте сообщество, создавайте и планируйте мероприятия. Ваши мероприятия увидят ребята по всей стране.",
    buttonText: "Добавить",
    disabledText: "Добавлено",
    disabled: false,
    link: "/group/events/create/",
  },
];
const other = [
  {
    image: "student_fill_profile",
    title: "Заполнить профиль",
    text: "Пользуйся рекомендательными возможностям и отслеживай только интересные для тебя мероприятия.",
    buttonText: "Заполнить",
    disabledText: "Заполнено",
    disabled: false,
  },
  {
    image: "student_seach_activity",
    title: "Найти занятия",
    text: "Планируй время с пользой для себя. Участвуй в конференциях, олимпиадах, конкурсах, фестивалях и многих других мероприятиях.",
    buttonText: "Найти",
    disabledText: "",
    disabled: false,
  },
  {
    image: "student_community",
    title: "Вступить в сообщества",
    text: "Находи интересные сообщества и следи за их обновлениями, получай свежую информацию о новых мероприятиях и активностях.",
    buttonText: "Вступить",
    disabledText: "",
    disabled: false,
  },
  {
    image: "student_progress",
    title: "Добавить достижения",
    text: "Добавь предыдущие достижения, ведь каждая активность идет в копилку твоего электронного портфолио. ",
    buttonText: "Добавить",
    disabledText: "",
    disabled: false,
  },
  {
    image: "organizer_fill_profile",
    title: "Заполнить профиль",
    text: "Украсьте вашу страницу при помощи аватара, фона и фото. Не забудьте добавить официальный сайт вашей организации.",
    buttonText: "Заполнить",
    disabledText: "Заполнено",
    disabled: false,
  },
  {
    image: "add_activity",
    title: "Добавить активность",
    text: "Планируйте и создавайте мероприятия. Отслеживайте активность обучающихся и их вовлеченность в образовательный процесс.",
    buttonText: "Добавить",
    disabledText: "Добавлено",
    disabled: false,
  },
  {
    image: "organizer_community",
    title: "Создать сообщество",
    text: "Формируйте сообщества внутри вашей организации. Расскажите ученикам о внеклассных мероприятиях.",
    buttonText: "Создать",
    disabledText: "Создано",
    disabled: false,

  },
  {
    image: "organizer_add_students",
    title: "Добавить обучающихся",
    text: "Добавляйте обучающихся вручную или с помощью массового загрузчика. Это быстро и просто!",
    buttonText: "Добавить",
    disabledText: "Добавлено",
    disabled: false,
  },
];

const Registration_Instruction = props => {


  const { userData } = props;

  console.log(userData)

  const [role, setRole] = useState(undefined)

  useEffect(() => {
    if (userData) {
      setRole(Object.keys(props.userData.roles)[0])
    }
  }, [userData])

  const [blockArr, setBlockArr] = useState([])


  useEffect(() => {
    if (role) {
      switch (role) {
        case '5':
          setBlockArr(student);
          break;
        case '7':
          setBlockArr(organize);
          break;
        case '8':
          setBlockArr(community);
          break;
        default:
          setBlockArr(community);
          break;
      }
    } else {
      setBlockArr(other);
    }
  }, [role])

  console.log(role)


 console.log(role);
   const collectChoiceBlocks = arr =>
     arr.map((props, key) => <СhoiceBlock key={key} {...props} />);

  return (
       <div className="hello-page main-block--non-top-margin">
        <Section sectionClassName="block-with-triangles" className="hello-page__header-inner">
         </Section>
         <div className="container">
           <div className="row hello-page__list">
             {collectChoiceBlocks(blockArr)}
           </div>
         </div>
         <Section className="news-list">
             <a href={File} className="promo__link promo__presentation-link btn">
             <img src={pdfIcon} alt="" />
               Скачать инструкцию
             </a>
          </Section>
       </div>
  )
};

export default Registration_Instruction;
