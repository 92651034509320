import React from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch } from "react-router-dom";

import { createBrowserHistory } from "history";

import Axios_requests from "./components/Global/Axios_requests";
import { getCookie } from "./components/Global/Cookie_function";
import { Auth, Events, Groups, Groups_Cabinet, Home, News, Instruction, Not_found, Organize_Cabinet, Password_forgot, Password_Reset, Portfolio, Public_Portfolio, Registration, Student_Cabinet, UserAgreement } from "./components/Global/Loadables";
import Loader from "./components/Global/Loader";
import Page_wrapper from "./components/Global/Page_wrapper";
import ScrollToTopRouter from "./components/Global/ScrollToTopRouter";
import "./style/style.scss";
import { getRole } from "./components/Global/Global_functions";
import DiaryAuth from "./components/Auth/DiaryAuth";
import PrivatePolicy from "./components/Index/PrivatePolicy";
import Promo_Page from "./components/Promo/Promo_Page/Promo_Page";
import EmailRestore from "./components/Auth/email-restore";
import Registration_Instruction from "./components/Registration/Registration_Instruction";

export const history = createBrowserHistory();

class App extends React.Component {
  state = {
    // userData: {},
    userCabinet: null,
    loading: true,
  };

  componentDidMount() {
    this.getUserData();
  }

  getUserData = (token = getCookie("token")) => {
    if (!token) {
      this.setState({ loading: false });
      return null;
    }

    const reqestLink = "/api/v1/profile/?info=short";
    return Axios_requests("get", reqestLink, null, token)
      .then(res => {
        const { data } = res.data;

        const role = this.handleUserData(data);
        return role;
      });
  };

  handleUserData = userData => {
    const role = getRole(userData.roles);
    const userCabinet = {
      5: <Route path="/student/" render={props =>
        <Student_Cabinet {...props} data={userData} getUserDataHandler={this.getUserData} />
      } />,
      7: <Route path="/org/" render={props =>
        <Organize_Cabinet {...props} data={userData} getUserDataHandler={this.getUserData} />
      } />,
      8: <Route path="/group/" render={props =>
        <Groups_Cabinet {...props} data={userData} getUserDataHandler={this.getUserData} />
      } />,
    }[role] || null;

    this.setState({ userData, userCabinet, loading: false });

    return role;
  }

  render = () => {
    const { userCabinet, userData, loading } = this.state;

    return (
      <>
        {loading
          ? <Loader />
          : <Page_wrapper header footer userData={userData} history={history}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/promo/" component={Promo_Page} />
              <Route exact path="/user-agreement/" component={UserAgreement} />
              <Route exact path="/private-policy/" component={PrivatePolicy} />
              <Route exact path="/auth/" render={props =>
                <Auth {...props} getUserData={this.getUserData} />
              } />
              <Route exact path="/auth/password-forget/" component={Password_forgot} />
              <Route path="/auth/restore/:id/:token" component={Password_Reset} />
              <Route path="/auth/restore/email/:token?" component={EmailRestore} />
              <Route exact path="/auth/diary/" render={props =>
                <DiaryAuth {...props} getUserData={this.getUserData} />
              } />
              <Route exact path="/portfolio/" component={Public_Portfolio} />
              <Route path="/portfolio/:id" component={Portfolio} />
              <Route path="/register" render={props =>
                <Registration {...props} userData={userData} getUserDataHandler={this.getUserData} />
              } />
              <Route path="/events/" render={props => <Events {...props} userData={userData} />} />
              <Route path="/groups/" render={props => <Groups {...props} userData={userData} />} />
              <Route path="/news/" component={News} />
              <Route path="/instruction/" render={props => <Registration_Instruction {...props} userData={userData} getUserDataHandler={this.getUserData} />} />
              {userCabinet}
              <Route path="*" component={Not_found} />
            </Switch>
          </Page_wrapper>
        }
      </>
    );
  }
}

ReactDOM.render(
  <Router history={history}>
    <ScrollToTopRouter>
      <App />
    </ScrollToTopRouter>
  </Router>,

  document.getElementById("root"),
);
