import cn from "classnames";
import React from "react";
import "./field-wrapper.scss";

const FieldWrapper = ({ className, field, error }) => (
  <div className={cn(className, "fieldWrapper")}>
    <div className="fieldSubWrapper">
      <div className="fieldWrapperField">{field}</div>
      <div className="fieldWrapperError">{error}</div>
    </div>
  </div>
);

FieldWrapper.defaultProps = {
  className: "",
};

export default FieldWrapper;
