const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: null,
  message: "",
  loaderStatus: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "loading": {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isFailure: false,
        message: action.message || "",
        loaderStatus: 0,
      };
    }
    case "success": {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: {
          ...state.data,
          ...action.payload,
        },
        message: action.message,
        loaderStatus: 1,
      };
    }
    case "failure": {
      return {
        ...state,
        isLoading: false,
        isFailure: true,
        message: action.message,
        loaderStatus: 2,
      };
    }
    case "clean": {
      return {
        ...state,
        ...initialState,
      };
    }
    case "skipLoader": {
      return {
        ...state,
        loaderStatus: null,
      };
    }
    default: {
      return state;
    }
  }
};

export const requestReducer = {
  initialState,
  reducer,
};
