import React from "react";

class Card_panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={`card_panel ${
          this.props.className !== undefined ? this.props.className : ""
        }`}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Card_panel;
