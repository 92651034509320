import React from "react";
import { Link } from "react-router-dom";

const FormsLink = ({ to, children }) => (
  <div className="bottom-links__item-wrapper">
    <Link to={to} className="link">
      {children}
    </Link>
  </div>
);

export default FormsLink;
