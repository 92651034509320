export function getCookie(cookieName) {
  let results = document.cookie.match("(^|;) ?" + cookieName + "=([^;]*)(;|$)");

  if (results) {
    return unescape(results[2]);
  } else {
    return null;
  }
}

export function deleteCookie(cookieName) {
  let date = new Date();

  date.setTime(date.getTime() - 1);

  let cookieExpires = date.toGMTString();

  document.cookie = `${cookieName}=; path=/; expires=${cookieExpires}`;
}

export function setCookie(cookieName, token) {
  document.cookie = `${cookieName}=${token}; path=/;`;
}
