import axios from "axios";

import { deleteCookie } from "./Cookie_function";
import getDomain from "./getDomain";

function Axios_requests(method, url, data, token, additionalHeader) {
  function axiosResult(promise) {
    return promise.then(null, onRejected => {
      if (onRejected.message.indexOf("401") > 0) {
        deleteCookie("token");
        if (window.location.pathname !== "/auth/") {
          // window.history.pushState('', '', '/auth/');
          window.location.replace("/auth/");
        }
      }
      throw onRejected;
    });
  }

  if (token) {
    axios.defaults.headers["x-token"] = token;
  }

  if (additionalHeader) {
    axios.defaults.headers[additionalHeader.type] = additionalHeader.text;
  }

  const domain = getDomain();
  let promise = "";

  switch (method) {
    case "put":
      promise = axios.put(domain + url, { data: data });

      return axiosResult(promise);

    case "get":
      var dataForGet = "";

      if (data) {
        dataForGet = { params: data };
      }

      promise = axios.get(domain + url, dataForGet);

      return axiosResult(promise);

    case "getFile":
      if (data) {
        promise = axios.get(domain + url, {
          params: data,
          responseType: "blob",
        });

        return axiosResult(promise);
      }

      promise = axios.get(domain + url, { responseType: "blob" });

      return axiosResult(promise);

    case "post":
      promise = axios.post(domain + url, { data: data });

      return axiosResult(promise);

    case "postData":
      promise = axios.post(domain + url, data);

      return axiosResult(promise);

    case "delete":
      promise = axios.delete(domain + url, { data: { data: data } });

      return axiosResult(promise);

    case "options":
      break;
    case "patch":
      break;
    default:
      break;
  }
}

export default Axios_requests;
