import cn from "classnames";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";

const Form_Loader = ({ status, message }) => {
  const isLoading = status === 0;
  const isSuccess = status === 1;
  const isFailure = status === 2;

  const LOADING_CLASS = "loading-form";
  const { classList } = document.body;
  const hasLoadingClass = classList.contains(LOADING_CLASS);

  if (isLoading || isSuccess || isFailure) {
    classList.add(LOADING_CLASS);
  } else if (hasLoadingClass) {
    classList.remove(LOADING_CLASS);
  }

  useEffect(() => () => classList.remove(LOADING_CLASS), [classList]);

  return createPortal(
    (
      <div
        className={cn(
          "loading-message",
          { "loading-message--displayed": isLoading },
          { "loading-message--true": isSuccess },
          { "loading-message--false": isFailure },
        )}
      >
        {(isLoading && !message)
          ? "Производится проверка данных"
          : message
        }
      </div>
    ), document.body,
  );
};

export default Form_Loader;
