
import Axios_requests from "./Axios_requests";
import Loader from "./Loader";
import Section from "./Section";
import Card_panel from "./Card_panel";
import Heading from "./Heading";

export {
  Axios_requests,
  Loader,
  Section,
  Card_panel,
  Heading,
};
