import React from "react";
import { Link } from "react-router-dom";

import Card_panel from "../Global/Card_panel";
import Page_header from "../Global/Page_header";

class PrivatePolicy extends React.Component {
  render() {
    return (
      <div>
        <section id="user-agreement">
          <div className="container">
            <Page_header
              className="question__control-panel"
              heading="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ В ОТНОШЕНИИ ОБРАБОТКИ
              ПЕРСОНАЛЬНЫХ ДАННЫХ
              ООО «Школьный навигатор»"
              headingClass="question__heading"
            />
            <Card_panel>
              <p>
                Общество с ограниченной ответственностью «Школьный навигатор»
                (ИНН 7840067437, ОГРН 1177847206998, адрес (место нахождения):
                191023, г. Санкт- Петербург ул. Гороховая, д.30 литера А,
                помещение 1Н, офис 8) является надлежащим образом учрежденным и
                зарегистрированным юридическим лицом в соответствии с
                законодательством Российской Федерации.
              </p>
              <ol className="sub-listings">
                <li className="no-number">
                  <h2>1. Общие положения</h2>
                  <ol className="sub-listings">
                    <li>
                      Настоящий документ определяет политику ООО «Школьный
                      навигатор» в отношении обработки и защиты персональных
                      данных посетителей и зарегистрированных пользователей
                      сайта:{" "}
                      <a href="https://edunavi.online/">
                        https://edunavi.online/
                      </a>
                      , клиентов и контрагентов ООО «Школьный навигатор».
                    </li>

                    <li>
                      {" "}
                      Политика в отношении обработки персональных данных ООО
                      «Школьный навигатор» (далее по тексту - «Политика»)
                      разработана в соответствии с Конституцией Российской
                      Федерации, Трудовым кодексом Российской Федерации,
                      Гражданским кодексом Российской Федерации, Федеральным
                      законом от 27.07.2006 г. №152-ФЗ «О персональных данных»,
                      Федеральным законом от 27 июля 2006 года № 149-ФЗ «Об
                      информации, информационных технологиях и о защите
                      информации», Постановлением Правительства РФ от 01.11.2012
                      № 1119 «Об утверждении требований к защите персональных
                      данных при их обработке в информационных системах
                      персональных данных» и иными нормативными правовыми актами
                      Российской Федерации в области охраны (защиты)
                      персональных данных.{" "}
                    </li>
                    <li>
                      Настоящая Политика разработана в целях реализации
                      требований законодательства Российской Федерации в области
                      обработки персональных данных и направлена на обеспечение
                      защиты прав и свобод человека и гражданина при обработке
                      его персональных данных, в том числе на защиту от
                      несанкционированного доступа к персональным данным
                      посетителей и зарегистрированных пользователей сайта.{" "}
                    </li>
                    <li>
                      Действие настоящей Политики распространяется на все
                      персональные данные посетителей и зарегистрированных
                      пользователей сайта, клиентов, контрагентов ООО «Школьный
                      навигатор» и на информацию, обрабатываемую ООО «Школьный
                      навигатор», автоматизировано или без применения средств
                      автоматизации.{" "}
                    </li>
                    <li>
                      ООО «Школьный навигатор» осуществляет сбор персональных
                      данных с использованием сети Интернет. Неограниченный
                      доступ к нижеследующей Политике обеспечивается посредством
                      опубликования (размещения) ее на сайте Оператора:{" "}
                      <a href="https://edunavi.online/">
                        https://edunavi.online/
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>
                    2. Основные термины и определения, используемые в настоящей
                    Политике
                  </h2>
                  <ol className="sub-listings">
                    <li>
                      Основные понятия, используемые в Политике:
                      <br />
                      <ul>
                        <li>
                          персональные данные – любая информация, относящаяся к
                          прямо или косвенно определенному или определяемому
                          физическому лицу (субъекту персональных данных);
                        </li>
                        <li>
                          оператор персональных данных (оператор) – ООО
                          «Школьный навигатор», самостоятельно или совместно с
                          другими лицами организующее и (или) осуществляющее
                          обработку персональных данных, а также определяющие
                          цели обработки персональных данных, состав
                          персональных данных, подлежащих обработке, действия
                          (операции), совершаемые с персональными данными;{" "}
                        </li>
                        <li>
                          субъект персональных данных (пользователь) -
                          посетитель или зарегистрированный пользователь сайта,
                          клиент или контрагент Оператора;{" "}
                        </li>
                        <li>
                          сайт/онлайн-сервис - Интернет-сайт по адресу:
                          https://edunavi.online/{" "}
                        </li>
                        <li>
                          обработка персональных данных – любое действие
                          (операция) или совокупность действий (операций) с
                          персональными данными, совершаемых с использованием
                          средств автоматизации или без их использования.
                          Обработка персональных данных включает в себя, в том
                          числе: сбор, запись, систематизацию, накопление,
                          хранение, уточнение (обновление, изменение),
                          извлечение, использование, передачу (распространение,
                          предоставление, доступ), обезличивание, блокирование,
                          удаление, уничтожение персональных данных;{" "}
                        </li>
                        <li>
                          автоматизированная обработка персональных данных -
                          обработка персональных данных с помощью средств
                          вычислительной техники;{" "}
                        </li>
                        <li>
                          распространение персональных данных - действия,
                          направленные на раскрытие персональных данных
                          неопределенному кругу лиц;{" "}
                        </li>
                        <li>
                          предоставление персональных данных - действия,
                          направленные на раскрытие персональных данных
                          определенному лицу или определенному кругу лиц;{" "}
                        </li>
                        <li>
                          блокирование персональных данных - временное
                          прекращение обработки персональных данных (за
                          исключением случаев, если обработка необходима для
                          уточнения персональных данных);{" "}
                        </li>
                        <li>
                          уничтожение персональных данных - действия, в
                          результате которых становится невозможным восстановить
                          содержание персональных данных в информационной
                          системе персональных данных и (или) в результате
                          которых уничтожаются материальные носители
                          персональных данных;{" "}
                        </li>
                        <li>
                          обезличивание персональных данных - действия, в
                          результате которых становится невозможным без
                          использования дополнительной информации принадлежность
                          персональных данных конкретному субъекту персональных
                          данных;{" "}
                        </li>
                        <li>
                          информационная система персональных данных -
                          совокупность содержащихся в базах данных персональных
                          данных и обеспечивающих их обработку информационных
                          технологий и технических средств;{" "}
                        </li>
                        <li>
                          трансграничная передача персональных данных - передача
                          персональных данных на территорию иностранного
                          государства органу власти иностранного государства,
                          иностранному физическому лицу или иностранному
                          юридическому лицу.{" "}
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>3. Правовые основания обработки персональных данных</h2>
                  <ol className="sub-listings">
                    <li>
                      Оператор осуществляет обработку персональных данных
                      субъекта в соответствии со следующими нормативными
                      правовыми актами:
                      <br />
                      <ul>
                        <li>Конституция Российской Федерации;</li>
                        <li>Трудовой кодекс Российской Федерации;</li>
                        <li>Гражданский кодекс Российской Федерации;</li>
                        <li>
                          Федеральный закон от 27.07.2006 г. № 152-ФЗ «О
                          персональных данных»;
                        </li>
                        <li>
                          Федеральный закон от 27 июля 2006 года № 149-ФЗ «Об
                          информации, информационных технологиях и о защите
                          информации»;
                        </li>
                        <li>
                          Постановление Правительства РФ от 01.11.2012 № 1119
                          «Об утверждении требований к защите персональных
                          данных при их обработке в информационных системах
                          персональных данных»;
                        </li>
                        <li>
                          Постановление Правительства РФ от 15.09.2008 № 687 «Об
                          утверждении Положения об особенностях обработки
                          персональных данных, осуществляемой без использования
                          средств автоматизации»;
                        </li>
                        <li>
                          Приказ ФСТЭК России от 18 февраля 2013 г. № 21 «Об
                          утверждении и содержания организационных и технических
                          мер по обеспечению безопасности персональных данных
                          при их обработке в информационных системах
                          персональных данных»;
                        </li>
                        <li>
                          Приказ Роскомнадзора от 05 сентября 2013 г. № 996 «Об
                          утверждении требований и методов по обезличиванию
                          персональных данных»;
                        </li>
                        <li>
                          Приказ ФСБ России от 9 февраля 2005г. № 66 «Об
                          утверждении Положения о разработке, производстве,
                          реализации и эксплуатации (криптографических) средств
                          защиты информации»;
                        </li>
                        <li>
                          Иные нормативные правовые акты Российской Федерации.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>4. Цели обработки персональных данных</h2>
                  <ol className="sub-listings">
                    <li>
                      Персональные данные обрабатываются Оператором в следующих
                      целях:
                      <ol className="sub-listings">
                        <li>
                          Регистрации на сайте/онлайн-сервисе и предоставлении
                          доступа к его содержанию;
                        </li>
                        <li>
                          Связи с зарегистрированными пользователями
                          сайта-субъектами персональных данных (пользователями),
                          в том числе способом направления уведомлений, запросов
                          и информации, касающихся работы с онлайн-сервисом,
                          оказания услуг пользователю сайта, а также в целях
                          обработки запросов и заявок от пользователя сайта;{" "}
                        </li>
                        <li>
                          Улучшения качества работы онлайн-сервиса, удобства его
                          использования, разработки новых;
                        </li>
                        <li>
                          Проведения статистических и иных исследований, на
                          основе обезличенных данных;
                        </li>
                        <li>
                          Таргетирования рекламных и информационных материалов;
                        </li>
                        <li>
                          Выполнение требований законодательства по определению
                          порядка обработки и защиты персональных данных
                          граждан, зарегистрированным пользователем сайта,
                          клиентом или контрагентом Оператора;
                        </li>
                        <li>
                          Осуществления прав и законных интересов ООО «Школьный
                          навигатор» в рамках осуществления видов деятельности,
                          предусмотренных Уставом и иными локальными
                          нормативными актами ООО «Школьный навигатор», или
                          третьих лиц либо достижения общественно значимых
                          целей;{" "}
                        </li>
                        <li>В иных законных целях.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>5. Принципы и условия обработки персональных данных</h2>
                  <ol className="sub-listings">
                    <li>
                      Обработка персональных данных субъекта персональных данных
                      осуществляется с учетом необходимости обеспечения защиты
                      прав и свобод субъекта персональных данных, в том числе
                      защиты права на неприкосновенность частной жизни, личную и
                      семейную тайну, на основе следующих принципов:
                      <ol className="sub-listings">
                        {" "}
                        <li>
                          Обработка персональных данных осуществляется на
                          законной и справедливой основе;
                        </li>
                        <li>
                          {" "}
                          Обработка персональных данных ограничивается
                          достижением конкретных, заранее определенных и
                          законных целей, установленных настоящей Политикой;
                        </li>
                        <li>
                          {" "}
                          Не допускается обработка персональных данных,
                          несовместимая с целями сбора персональных данных,
                          установленных настоящей Политикой;
                        </li>
                        <li>
                          {" "}
                          Не допускается объединение баз данных, содержащих
                          персональные данные, обработка которых осуществляется
                          в целях, несовместимых между собой;
                        </li>
                        <li>
                          {" "}
                          Обработке подлежат только персональные данные, которые
                          отвечают целям их обработки;
                        </li>
                        <li>
                          {" "}
                          Содержание и объем обрабатываемых персональных данных
                          соответствуют заявленным целям обработки и не являются
                          избыточными по отношению к заявленным целям их
                          обработки;
                        </li>
                        <li>
                          {" "}
                          При обработке персональных данных обеспечивается
                          точность персональных данных, их достаточность, а в
                          необходимых случаях и актуальность по отношению к
                          целям обработки персональных данных;
                        </li>
                        <li>
                          {" "}
                          Принимаются необходимые меры по удалению или уточнению
                          неполных, или неточных данных;
                        </li>
                        <li>
                          {" "}
                          Хранение персональных данных осуществляется в форме,
                          позволяющей определить субъекта персональных данных,
                          не дольше, чем этого требуют цели обработки
                          персональных данных, если иной срок хранения
                          персональных данных не предусмотрен федеральным
                          законом или договором, стороной которого,
                          выгодоприобретателем или поручителем, по которому
                          является субъект персональных данных;
                        </li>
                        <li>
                          {" "}
                          Обрабатываемые персональные данные по достижении целей
                          обработки или в случае утраты необходимости в
                          достижении этих целей, если иное не предусмотрено
                          федеральным законом или договором (стороной которого,
                          выгодоприобретателем или поручителем персональных
                          данных) подлежат уничтожению либо обезличиванию.
                        </li>
                      </ol>
                      <li>
                        Оператор осуществляет обработку персональных данных
                        субъектов в случаях, установленных законодательством
                        Российской Федерации в области персональных данных.
                        Одним из таких случаев является предоставление субъектом
                        согласия на обработку его персональных данных. Субъект
                        персональных данных принимает решение о предоставлении
                        его персональных данных и дает согласие на их обработку
                        свободно, своей волей и в своем интересе. Оператор
                        обеспечивает получение конкретного, информированного и
                        сознательного согласия субъекта на обработку его
                        персональных данных. Оператор обрабатывает персональные
                        данные только при наличии хотя бы одного из следующих
                        условий:
                        <ul>
                          <li>
                            обработка персональных данных осуществляется с
                            согласия субъекта персональных данных на обработку
                            его персональных данных;
                          </li>
                          <li>
                            обработка персональных данных необходима для
                            достижения целей, предусмотренных законом, для
                            осуществления и выполнения возложенных
                            законодательством Российской Федерации на оператора
                            функций, полномочий и обязанностей;
                          </li>
                          <li>
                            обработка персональных данных необходима для
                            осуществления прав и законных интересов Оператора
                            или третьих лиц либо для достижения общественно
                            значимых целей при условии, что при этом не
                            нарушаются права и свободы субъекта персональных
                            данных;
                          </li>
                          <li>
                            осуществляется обработка персональных данных, доступ
                            неограниченного круга лиц к которым предоставлен
                            субъектом персональных данных либо по его просьбе;
                          </li>
                          <li>
                            осуществляется обработка персональных данных,
                            подлежащих опубликованию или обязательному раскрытию
                            в соответствии с федеральным законом.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Оператор и иные лица, получившие доступ к персональным
                        данным, обязаны не раскрывать третьим лицам и не
                        распространять персональные данные без согласия субъекта
                        персональных данных, если иное не предусмотрено
                        федеральным законом.
                      </li>
                      <li>
                        Оператор вправе поручить обработку персональных данных
                        другому лицу с согласия субъекта персональных данных,
                        если иное не предусмотрено федеральным законом, на
                        основании заключаемого с этим лицом договора. Лицо,
                        осуществляющее обработку персональных данных по
                        поручению Оператора, обязано соблюдать принципы и
                        правила обработки персональных данных, предусмотренные
                        Федерального закона от 27.07.2006 г. №152-ФЗ «О
                        персональных данных».
                      </li>
                      <li>
                        Работники Оператора, ведущие обработку персональных
                        данных, не отвечают на вопросы, связанные с передачей
                        персональных данных по телефону или факсу.
                      </li>
                      <li>
                        Во время посещения Пользователем сайта Оператору
                        доступна техническая информация, автоматически
                        собираемая программным обеспечением сайта во время его
                        посещения. Так, во время посещения субъектом
                        персональных данных сайта автоматически становится
                        доступна информация из стандартных журналов сервера
                        (server logs). Например, IP-адрес персонального
                        компьютера (прокси-сервера), имя Интернет- провайдера,
                        имя домена, тип браузера, операционной системы,
                        информация о сайте с которого совершил субъект
                        персональных данных, переход на сайт, посещенных
                        субъектом персональных данных страницах Сайта, дате и
                        времени посещения Сайта. Эта информация может
                        анализироваться Оператором в обезличенном виде для
                        анализа посещаемости сайта, выработки предложений по его
                        оптимизации и развитию.
                      </li>
                      <li>
                        Обработка специальных категорий персональных данных,
                        касающихся расовой, национальной принадлежности,
                        политических взглядов, религиозных или философских
                        убеждений, интимной жизни, Оператором не осуществляется.
                      </li>
                      <li>
                        Обработка персональных данных, предоставленных субъектом
                        персональных данных, осуществляется Оператором
                        следующими способами:
                        <ol>
                          <li>
                            {" "}
                            Неавтоматизированная обработка персональных данных;
                          </li>
                          <li>
                            Обработка персональных данных с использованием
                            автоматизации;
                          </li>
                          <li>Смешанная обработка персональных данных.</li>
                        </ol>
                      </li>
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>
                    6. Права и обязанности субъекта персональных данных и
                    оператора:
                  </h2>
                  <ol className="sub-listings">
                    <li>
                      Права и обязанности субъекта персональных данных:
                      <br />
                      <b>Субъект персональных данных/пользователь вправе:</b>
                      <br />
                      <ul>
                        <li>
                          субъект персональных данных имеет право на получение
                          информации, касающейся обработки его персональных
                          данных; состав предоставляемых сведений, а также
                          порядок, правила и сроки их предоставления. Право
                          субъекта персональных данных на доступ к его
                          персональным данным может быть ограничено в
                          соответствии с федеральными законами, в том числе,
                          если обработка персональных данных осуществляется в
                          соответствии с законодательством о противодействии
                          легализации (отмыванию) доходов, полученных преступным
                          путем, и финансированию терроризма.
                        </li>
                        <li>
                          субъект персональных данных вправе требовать от
                          Оператора уточнения его персональных данных, их
                          блокирования или уничтожения в случае, если
                          персональные данные являются неполными, устаревшими,
                          неточными, незаконно полученными или не являются
                          необходимыми для заявленной цели обработки, а также
                          принимать предусмотренные законом меры по защите своих
                          прав.
                        </li>
                        <li>
                          если субъект персональных данных считает, что Оператор
                          осуществляет обработку его персональных данных с
                          нарушением требований или иным образом нарушает его
                          права и свободы, субъект персональных данных вправе
                          обжаловать действия или бездействие Оператора в
                          Уполномоченный орган по защите прав субъектов
                          персональных данных или в судебном порядке. Субъект
                          персональных данных имеет право на защиту своих прав и
                          законных интересов, в том числе на возмещение убытков
                          и (или) компенсацию морального вреда в судебном
                          порядке.
                        </li>
                        <li>
                          направить Оператору отзыв согласия на обработку
                          персональных данных.
                        </li>
                        <li>
                          направлять Оператору запросы на получение информации,
                          касающейся обработки его персональных данных
                          (относительно использования его персональных данных) в
                          форме электронного документа на адрес электронной
                          почты: info@edunavi.online.
                        </li>
                        <li>
                          осуществлять иные права субъекта персональных данных,
                          включая право на обжалование действий или бездействий
                          Оператора, установленные законом.
                        </li>
                      </ul>
                      <br />
                      <b>Субъект персональных данных/пользователь обязан:</b>
                      <br />В целях, чтобы третьи лица не получили доступ к
                      учетной записи субъекта персональных данных сайта, субъект
                      персональных данных обязан соблюдать ряд приведенных ниже
                      рекомендаций:
                      <ul>
                        <li>
                          отдавать преимущество защищённому протоколу (HTTPS)
                          при подключении к ресурсам Сайта;
                        </li>
                        <li>
                          не использовать электронную почту и средства
                          оперативной отправки сообщений (SMS, Viber, WhatsApp,
                          Skype, ICQ и пр.) для передачи логина (имя
                          пользователя) и пароля;
                        </li>
                        <li>
                          не использовать простые или имеющие смысловую нагрузку
                          пароли;
                        </li>
                        <li>
                          Пароль должен представлять собой не имеющие смысла
                          сочетание букв, цифр и знаков верхнего и нижнего
                          регистра;
                        </li>
                        <li>
                          завершать сессию работы на Сайте под своей учетной
                          записью.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Права и обязанности Оператора:
                      <br />
                      <b>Оператор вправе:</b>
                      <br />
                      <ul>
                        <li>
                          предоставлять персональные данные третьим лицам при
                          наличии согласия на это субъекта персональных данных
                          или в соответствии с требованиями, предусмотренными
                          законодательством РФ;
                        </li>
                        <li>
                          продолжать обработку персональных данных после отзыва
                          согласия субъектом персональных данных в случаях,
                          предусмотренных Законом;
                        </li>
                        <li>
                          мотивированно отказать субъекту персональных данных
                          (его представителю) в удовлетворении запроса о
                          предоставлении информации, касающейся обработки
                          персональных данных субъекта, при наличии оснований,
                          предусмотренных законодательством Российской
                          Федерации. Обязанности Оператора:
                        </li>
                        <li>
                          при обработке персональных конфиденциальность
                          обрабатываемых персональных данных, а также выполнять
                          иные требования, предусмотренные законодательством
                          Российской Федерации в области персональных данных.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>7. Персональные данные:</h2>
                  <ol className="sub-listings">
                    <li>
                      Акцептуя настоящее предложение политики
                      конфиденциальности, пользователь предоставляет свое
                      согласие на обработку своих персональных данных, как без
                      использования средств автоматизации, так и с их
                      использованием.
                    </li>
                    <li>
                      Согласие дается на обработку следующих персональных данных
                      пользователя: фамилия, имя, отчество, место работы/учебы,
                      должность/класс, номер телефона, адрес личной электронной
                      почты, а также хранение, сбор, уточнение (обновление,
                      изменение), удаление и обработку/использование данных о
                      географическом положении (геолокация/географические
                      координаты).
                    </li>
                    <li>
                      В целях реализации своего полноценного функционала
                      приложение онлайн-сервиса может осуществлять доступ к
                      камерам устройства с целью получения и использования
                      фотоизображений в рамках услуг, реализуемых в
                      онлайн-сервисе, в том числе для создания и сохранения
                      фотоизображений в профиле пользователя в онлайн-сервисе.
                    </li>
                    <li>
                      {" "}
                      Настоящим субъект персональных данных подтверждает, что,
                      размещая свои персональные данные в разделе «Личный
                      кабинет», он признает общедоступный характер данной
                      информации и дает свое согласие на то, что соответствующие
                      данные являются общедоступными. Пользователь также путем
                      акцепта настоящей Политики дает согласие на обработку его
                      персональных данных как Администрацией онлайн-сервиса, так
                      и другими Пользователями.
                    </li>
                    <li>
                      {" "}
                      Основанием для обработки персональных данных Пользователя
                      являются Федеральный закон №152-ФЗ «О персональных данных»
                      от 27 июля 2006 г., Постановление Правительства РФ от
                      01.11.2012 № 1119 «Об утверждении Положения об обеспечении
                      безопасности персональных данных при их обработке в
                      информационных системах персональных данных».
                    </li>
                    <li>
                      {" "}
                      Учитывая, что персональные данные с согласия Пользователя
                      являются общедоступными, тем не менее, администрация
                      онлайн-сервиса при обработке персональных данных обязуется
                      принять все организационные и технические меры для их
                      защиты от несанкционированного доступа способом, не
                      предусмотренным онлайн- сервисом.
                    </li>
                    <li>
                      {" "}
                      В ходе обработки с персональными данными будут совершены
                      следующие действия: сбор, запись, систематизация,
                      накопление, хранение, уточнение (обновление, изменение),
                      извлечение, использование, передачу (распространение,
                      предоставление, доступ), обезличивание, блокирование,
                      удаление, уничтожение.
                    </li>
                    <li>
                      {" "}
                      Также может осуществляется сбор данных, которые не
                      указывают непосредственно на конкретного человека: язык,
                      почтовый индекс, код города, URL-адрес источника ссылки,
                      местоположение и часовой пояс. Администрация сервиса может
                      собирать, использовать, передавать и раскрывать такие
                      сведения с любой целью.
                    </li>
                    <li>
                      {" "}
                      Также может осуществляется сбор данных, которые не
                      указывают непосредственно на конкретного человека: язык,
                      почтовый индекс, код города, URL-адрес источника ссылки,
                      местоположение и часовой пояс. Администрация сервиса может
                      собирать, использовать, передавать и раскрывать такие
                      сведения с любой целью.
                    </li>
                    <li>
                      {" "}
                      Персональные данные обрабатываются до прекращения
                      деятельности ООО «Школьный навигатор».
                    </li>
                    <li>
                      {" "}
                      Согласие дается, в том числе, на информационные
                      (рекламные) оповещения.
                    </li>
                    <li>
                      {" "}
                      Пользователь может прекратить обработку своих персональных
                      данных ООО «Школьный навигатор» и удалить свой аккаунт на
                      сайте https://edunavi.online путем отзыва Пользователем
                      или его законным представителем согласия на обработку
                      персональных данных, путем направления письменного
                      заявления (запроса) в ООО «Школьный навигатор» или ее
                      представителю почтой или электронным письмом по адресу,
                      указанному в начале Политики.
                    </li>
                    <li>
                      {" "}
                      После получения соответствующего заявления (запроса) ООО
                      «Школьный навигатор» в течение 30 дней прекращает
                      обработку и хранение персональных данных Пользователя,
                      удаляя его персональные данные с онлайн-сервиса и баз
                      данных.
                    </li>
                    <li>
                      {" "}
                      Пользователь вправе отредактировать предоставленную им в
                      своем Личном кабинете при регистрации или авторизации
                      Персональную информацию путем направления заявления
                      (запроса), в т.ч. в электронном виде, в ООО «Школьный
                      навигатор» по адресу, указанному в начале
                      Пользовательского Соглашения, с приложением документов,
                      подтверждающих факт изменения персональных данных.
                    </li>
                    <li>
                      {" "}
                      Настоящее согласие действует все время до момента
                      прекращения обработки персональных данных, указанных в
                      п.7.9. и п.7.11. данного соглашения.
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>
                    8. Изменение, блокирование, уничтожение персональных данных
                  </h2>
                  <ol className="sub-listings">
                    <li>
                      Оператор обязуется внести по требованию субъекта
                      персональных данных необходимые изменения, блокировать его
                      персональные данные или уничтожить в случае, если
                      персональные данные являются неполными, устаревшими,
                      неточными, незаконно полученными или не являются
                      необходимыми для заявленной настоящей Политикой цели. О
                      внесенных изменениях и предпринятых мерах Оператор
                      уведомляет субъекта персональных данных в течение тридцати
                      календарных дней с момента получения требования субъекта
                      персональных данных о необходимости изменить, блокировать
                      или уничтожить его персональные данные.{" "}
                    </li>
                    <li>
                      В случае выявления недостоверных персональных данных
                      субъекта персональных данных или неправомерных действий с
                      ними Оператор при обращении или по запросу субъекта
                      персональных данных осуществляет блокирование персональных
                      данных, относящихся к данному субъекту персональных
                      данных, с момента такого обращения на период проверки.{" "}
                    </li>
                    <li>
                      В случае подтверждения факта недостоверности персональных
                      данных субъекта персональных данных, Оператор уточняет
                      персональные данные у субъекта персональных данных и
                      снимает их блокирование.{" "}
                    </li>
                    <li>
                      {" "}
                      В случае выявления неправомерных действий с персональными
                      данными субъекта персональных данных, Оператор в срок, не
                      превышающий трех рабочих дней с даты выявления, обязан
                      устранить допущенные нарушения. В случае невозможности
                      устранения допущенных нарушений Оператор в срок, не
                      превышающий трех рабочих дней с даты выявления
                      неправомерности действий с персональными данными субъекта
                      персональных данных, обязан уничтожить персональные
                      данные. Об устранении допущенных нарушений или об
                      уничтожении персональных данных Оператор уведомляет
                      субъекта персональных данных.{" "}
                    </li>
                    <li>
                      По истечении трех лет с момента последней активности
                      субъекта персональных данных на сайте Оператор прекращает
                      обработку (хранение) его персональных данных, если иной
                      срок хранения персональных данных не установлен
                      федеральным законом или Договором (стороной которого,
                      выгодоприобретателем или поручителем, по которому является
                      субъект персональных данных).
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>9. Обеспечение безопасности персональных данных</h2>
                  <ol className="sub-listings">
                    <li>
                      Безопасность персональных данных, обрабатываемых
                      Оператором обеспечивается реализацией правовых,
                      организационных, технических и программных мер,
                      необходимых и достаточных для обеспечения требований
                      федерального законодательства в области защиты
                      персональных данных.
                    </li>
                    <li>
                      Для целенаправленного создания у Оператора неблагоприятных
                      условий и труднопреодолимых препятствий для нарушителей,
                      пытающихся осуществить несанкционированный доступ к
                      персональным данным в целях овладения ими, их
                      видоизменения, уничтожения, заражения вредоносной
                      компьютерной программой, подмены и совершения иных
                      несанкционированных действий Оператора применяются
                      следующие организационно-технические меры:
                      <ul>
                        <li>
                          назначение должностных лиц, ответственных за
                          организацию обработки и защиты персональных данных;
                        </li>
                        <li>
                          ограничение и регламентация состава работников,
                          имеющих доступ к персональным данным;
                        </li>
                        <li>
                          генеральным директором Оператора утверждены локальные
                          акты по вопросам обработки персональных данных,
                          выявлению и предотвращению нарушений законодательства
                          Российской Федерации в сфере персональных данных и
                          устранению таких нарушений.
                        </li>
                        <li>
                          ознакомление работников с требованиями федерального
                          законодательства и нормативных документов Оператора по
                          обработке и защите персональных данных;
                        </li>
                        <li>
                          обеспечение учёта и хранения материальных носителей
                          информации и их обращения, исключающего хищение,
                          подмену, несанкционированное копирование и
                          уничтожение;
                        </li>
                        <li>
                          проверка готовности и эффективности использования
                          средств защиты информации;
                        </li>
                        <li>
                          персональные средства автоматизации, обособлены от
                          иной информации, в частности путем их отдельных
                          материальных специальных разделах и т.д.;
                        </li>
                        <li>
                          обеспечено раздельное хранение персональных данных и
                          их материальных носителей, обработка которых
                          осуществляется в разных целях и которые содержат
                          разные категории персональных данных;
                        </li>
                        <li>
                          персональные данные субъекта персональных данных
                          хранятся в электронном виде в информационных системах
                          Оператора, а также в архивных копиях баз данных этих
                          систем, с соблюдением условий, обеспечивающих
                          сохранность персональных данных и исключающих
                          несанкционированный доступ к ним;
                        </li>
                        <li>
                          установлен запрет на передачу персональных данных
                          субъекта персональных данных по открытым каналам связи
                          и сетям Интернет без применения установленных
                          Оператором мер по обеспечению безопасности
                          персональных данных, за исключением общедоступных и
                          (или) обезличенных персональных данных;
                        </li>
                        <li>
                          применение средств контроля доступа к коммуникационным
                          портам, устройствам ввода-вывода информации, съёмным
                          машинным носителям и внешним накопителям информации;
                        </li>
                        <li>
                          обеспечение восстановления персональных данных,
                          модифицированных или уничтоженных вследствие
                          несанкционированного доступа к ним; обучение
                          работников, использующих средства защиты информации,
                          применяемые в информационных системах персональных
                          данных, правилам работы с ними;
                        </li>
                        <li>
                          иные меры, предусмотренные законодательством
                          Российской Федерации в области персональных данных.
                        </li>
                      </ul>{" "}
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>10. Конфиденциальность</h2>
                  <ol className="sub-listings">
                    <li>
                      Персональные данные пользователя являются конфиденциальной
                      информацией, не подлежащей разглашению, и не могут быть
                      использованы Оператором для целей, не перечисленных в
                      настоящей Политики. Под разглашением персональных данных в
                      настоящей Политике понимается действие или бездействие, в
                      результате которых персональные данные, в любой возможной
                      форме (устной, письменной, иной форме, в том числе с
                      использованием технических средств) становятся известны
                      третьим лицам без согласия обладателя такой информации
                      либо вопреки гражданско-правовому договору.{" "}
                    </li>
                    <li>
                      Разглашение персональных данных субъекта персональных
                      данных или их части допускается только в случаях,
                      предусмотренных действующим законодательством Российской
                      Федерации, либо с отдельного согласия субъекта
                      персональных данных.{" "}
                    </li>
                    <li>
                      {" "}
                      Оператор не осуществляет трансграничную передачу
                      персональных данных субъекта персональных данных.
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>11. Файлы cookie</h2>
                  <ol className="sub-listings">
                    <li>
                      Файлы cookie представляют собой небольшой файл c
                      информацией о просмотренных страницах и настройках,
                      которые сохраняются на компьютерах пользователей.
                    </li>
                    <li>
                      {" "}
                      С целью улучшения функций сайта ООО «Школьный навигатор»
                      использует файлы cookie различных типов (технические,
                      функциональные, коммерческие, аналитические и т.д.). Если
                      субъект персональных данных не желает, что бы файлы cookie
                      сохранялись на его устройстве, необходимо настроить
                      соответствующим образом браузер. Однако в случае
                      отключения определенных файлов cookie субъекту
                      персональных данных могут быть недоступны некоторые
                      функции Сайта.
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>12. Ответственность</h2>
                  <ol className="sub-listings">
                    <li>
                      Оператор несет ответственность за нарушение обязательств
                      по обеспечению безопасности и конфиденциальности
                      персональных данных при их обработке в соответствии с
                      законодательством Российской Федерации.
                    </li>
                    <li>
                      Ответственность сотрудников Оператора, получившие доступ к
                      обрабатываемым персональным данным субъекта персональных
                      данных, за невыполнение требований норм, регулирующих
                      обработку и защиту персональных данных, определяется в
                      соответствии с законодательством Российской Федерации в
                      области обработки персональных данных и локальными актами
                      Оператора.
                    </li>
                  </ol>
                </li>
                <li className="no-number">
                  <h2>13. Заключительные положения</h2>
                  <ol className="sub-listings">
                    <li>
                      Во исполнение ч.2 ст. 18.1 Федерального закона от
                      27.07.2006 г. №152-ФЗ «О персональных данных» настоящая
                      Политика опубликована на сайте:{" "}
                      <a href="https://edunavi.online/">
                        https://edunavi.online/
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      Настоящая Политика является внутренним документом
                      Оператора.{" "}
                    </li>
                    <li>
                      Настоящая Политика вступает в силу с момента ее
                      опубликования на Сайте:{" "}
                      <a href="https://edunavi.online/">
                        https://edunavi.online/
                      </a>{" "}
                      и распространяется на персональные данные субъекта
                      персональных данных, полученные как до, так и после
                      вступления в силу настоящей Политики.{" "}
                    </li>
                    <li>
                      Оператор имеет право вносить изменения в настоящую
                      Политику, отражая их в тексте Политики, находящемся на
                      сайте Оператора. Изменения, вносимые в настоящую Политику,
                      вступают в силу со дня их подписания, если иное не
                      установлено самими изменениями. При внесении изменений в
                      заголовке Политики указывается дата последнего обновления
                      редакции.{" "}
                    </li>
                    <li>
                      Иные права и обязанности ООО «Школьный навигатор», как
                      Оператора персональных данных, определяются
                      законодательством Российской Федерации в области
                      персональных данных.
                    </li>
                  </ol>
                </li>
              </ol>
            </Card_panel>
            <Link to="/" className="btn btn--standart">
              Вернуться на главную
            </Link>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivatePolicy;
