import React from "react";
import ReactDOM from "react-dom";
import { Link, Route } from "react-router-dom";

import Btn from "../Form/Btn";
import Heading from "./Heading";

class Page_header extends React.Component {
  render() {
    return (
      <div
        className={`page__header${
          this.props.wrapperClass ? ` ${this.props.wrapperClass}` : ""
        }`}
      >
        <Heading
          className={`page__heading ${
            this.props.headingClass ? this.props.headingClass : ""
          }`}
          text={this.props.heading}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Page_header;
