import React from "react";

class Btn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <button
        id={this.props.id}
        type={this.props.type || "button"}
        className={this.props.className ? this.props.className : ""}
        onClick={this.props.onClick}
        title={this.props.title ? this.props.title : ""}
        disabled={this.props.disabled ? this.props.disabled : false}
      >
        {this.props.text ? this.props.text : ""}
        {this.props.children}
      </button>
    );
  }
}

export default Btn;
