import React from "react";

import Page_header from "./../../Global/Page_header";
import Section from "./../../Global/Section";
import Card_panel from "./../../Global/Card_panel";

import draftAgreementPdf from "./files/Комплексное решение Соглашение.pdf";
import draftAgreementClixPdf from "./files/Проект соглашения CLiX.pdf";
import schoolOlympusPdf from "./files/Presentation_Online_Service_School_Olympus.pdf";
import cognitiveRealityPdf from "./files/Presentation_Educational_Complex_Cognitive_Reality.pdf";
import mySportsClubPdf from "./files/Presentation_Online_Service_My_Sports_Club.pdf";
import clixPdf from "./files/Presentation_Online_Service_CLiX.pdf";
import educationalNavigatorPdf from "./files/Presentation_School_Educational_Navigator.pdf";
import pdfIcon from "../../../img/pdf.png";
import startupLogo from "./images/startupLogo.png";
import "./Promo_Page.scss";

const Promo_Page = () => {
  return (
    <div className="page-content">
      <Section>
        <Page_header heading="Комплексный проект «Омега.Образование» БЕСПЛАТНОЕ ПИЛОТИРОВАНИЕ" />
        <Card_panel>
          <p>
            Предлагаем Вам принять участие в бесплатном пилотировании и апробации <a href="http://co-reality.ru/" className="promo__link" target="_blank" rel="noopener noreferrer">образовательного комплекса «Познавательная реальность»</a> и других цифровых образовательных сервисов. Вы сможете создавать собственные интерактивные трехмерные уроки и на собственном опыте познакомиться со всеми преимуществами использования VR технологий в образовании!
          </p>
          <p>Алгоритм пилотирования максимально простой:</p>
          <ol className="promo__list">
            <li>Ознакомиться с проектами и определить список пилотируемых проектов
              <div className="promo__list-blocks">


                {/* list item block */}
                <div className="promo__list-item-block">
                  <h4 className="promo__list-title">Образовательный комплекс <a href="http://co-reality.ru/" className="promo__link" target="_blank" rel="noopener noreferrer">«Познавательная реальность»</a></h4>
                  <p className="promo__list-descr">интерактивные трехмерные уроки</p>
                  <a href={cognitiveRealityPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="" />
                    Скачать презентацию
                </a>
                  <div className="video__wrapper">
                    <iframe className="video__iframe"
                      src="https://www.youtube.com/embed/3SuWNeZLBSM"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>


                {/* list item block */}
                <div className="promo__list-item-block">
                  <h4 className="promo__list-title">Онлайн-сервис <a href="https://edunavi.online/" className="promo__link" target="_blank" rel="noopener noreferrer">«Образовательный навигатор школьника»</a></h4>
                  <p className="promo__list-descr">интерактивная образовательная среда</p>
                  <a href={educationalNavigatorPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="" />
                    Скачать презентацию
                </a>
                  <div className="video__wrapper">
                    <iframe className="video__iframe"
                      src="https://www.youtube.com/embed/kG0ZAFJVi9k"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>


                {/* list item block */}
                <div className="promo__list-item-block">
                  <h4 className="promo__list-title">Онлайн-сервис <a href="https://uud.school/" className="promo__link" target="_blank" rel="noopener noreferrer">«Школьный Олимп»</a></h4>
                  <p className="promo__list-descr">мониторинг УУД, soft & hard skills</p>
                  <a href={schoolOlympusPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="" />
                    Скачать презентацию
                </a>
                  <div className="video__wrapper">
                    <iframe className="video__iframe"
                      src="https://www.youtube.com/embed/x-sMd9CNb_s"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
                {/* list item block */}
                <div className="promo__list-item-block">
                  <h4 className="promo__list-title">Онлайн-сервис <a href="http://www.erida-sport.ru/" className="promo__link" target="_blank" rel="noopener noreferrer">«Мой спортивный клуб»</a></h4>
                  <p className="promo__list-descr">создание и управление спортивным клубом</p>
                  <a href={mySportsClubPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="" />
                    Скачать презентацию
                  </a>
                </div>

                {/* list item block */}
                <div className="promo__list-item-block">
                  <h4 className="promo__list-title">Онлайн-сервис <a href="https://clix.band/" className="promo__link" target="_blank" rel="noopener noreferrer">"CLiX"</a></h4>
                  <p className="promo__list-descr">естественная цифровая среда дошкольного образовательного учреждения</p>
                  <a href={clixPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="" />
                    Скачать презентацию
                  </a>
                </div>

              </div>
            </li>
            <li>Заключить Соглашение
              <a href={draftAgreementPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                <img src={pdfIcon} alt="" />
                Скачать проект Соглашения
              </a>
              <a href={draftAgreementClixPdf} className="promo__link promo__presentation-link" target="_blank" rel="noopener noreferrer">
                <img src={pdfIcon} alt="" />
                Скачать проект Соглашения Clix
              </a>
            </li>
            <li>Установить ПО и зарегистрироваться при поддержке и консультации наших специалистов</li>
            <li>Начать работать с цифровыми инструментами образования и оценить все преимущества от их использования</li>
          </ol>
          <div className="promo__form">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScmAKK-Dy42uKKabQOp01NtS-lWMP5C-ZWLCTGNwtvIRK5TZQ/viewform?embedded=true" width="100%" height="1190" frameBorder="0" marginHeight={0} marginWidth={0}>Загрузка…</iframe>
          </div>
          <p>Будем рады ответить на любые Ваши вопросы!</p>
          <p>E-mail: <a href="mailto:omega-ed@gkomega.ru" className="promo__link not-underline">omega-ed@gkomega.ru</a></p>
          <p>Телефон: <a href="tel:+78124480145" className="promo__link not-underline">+7 812 4480145</a></p>

          {/* <p>StartUP студия «Омега»</p> */}
          <img src={startupLogo} width="150px" height="auto" alt="" />
        </Card_panel>
      </Section>
    </div>
  );
};

export default Promo_Page;
