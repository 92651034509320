import PropTypes from "prop-types";
import React from "react";

import InputText from "../input-text/input-text";

const FormikInput = ({ form, field, type, isDisabled, autocomplete, className, label, height }) => {
  const { name, value, onChange, onBlur, onFocus } = field;
  const { errors, touched } = form;

  const isInputValid = !errors[name] && touched[name];
  const isInputError = errors[name] && touched[name];

  return (
    <InputText
      name={name}
      type={type}
      className={className}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      height={height}
      // @ts-ignore
      autocomplete={autocomplete}
      isValid={isInputValid}
      isError={isInputError}
      isDisabled={isDisabled}
    />
  );
};

FormikInput.propTypes = {
  autocomplete: PropTypes.string,
};

FormikInput.defaultProps = {
  autocomplete: "on",
};

export default FormikInput;
