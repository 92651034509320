/**
* @file DiaryAuth.jsx
*/

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Axios_requests, Loader, Section, Card_panel, Heading } from "../Global";
import { deleteCookie, setCookie } from "../Global/Cookie_function";
import { Cabinet_Routing } from "./../Global/Global_functions";

const FooterLink = ({ to, text }) => (
  <span className="bottom-links__item-wrapper">
    <Link to={to} className="link">
      {text}
    </Link>
  </span>
);

class DiaryAuth extends Component {
  state = {
    message: <Loader />,
    isValid: false,
  }

  showError = errMsg => this.setState({ message: errMsg, isValid: false });

  componentDidMount = () => {
    const { location: { pathname = "", search = "" } = {} } = this.props;

    // удаляем все параметры из урла
    this.cleanUrlFromParams(pathname);
    // получаем хэш из параметров
    const { hash } = this.getUrlParameters(search);

    // проверяем наличие hash, если его нет, выводим ошибку
    const isNotHasHash = !hash;
    if (isNotHasHash) {
      this.showError("Неверная ссылка для авторизации!");
      return;
    }

    // принудительно очищаем куки
    deleteCookie("token");
    // иначе делаем запрос на сервер
    this.sendHashOnServer(hash)
      .then(res => {
        const token = res.headers["x-token"];
        token ? this.login(token) : this.showError("Не удалось авторизоваться. Попробуйте заново.");
      })
      .catch(err => {
        const { response: { data } = {} } = err;

        if (!data) {
          this.showError("Запрос на авторизацию закончился с ошибкой.");
          return;
        }

        this.showError(data.data);
      });
  }

  // получаем объект с параметрами из строки
  getUrlParameters = string => {
    return string
      .replace("?", "")
      .split("&")
      .map(el => el.split("="))
      .reduce((acc, el) => ({ ...acc, [el[0]]: el[1] }), {});
  }

  // удаляем из урла get параметры
  cleanUrlFromParams = thisPagePathname => this.props.history.replace(thisPagePathname)

  // делаем запрос на сервер для получения токена авторизации
  sendHashOnServer = hash => {
    this.setState({ isValid: true });
    const queryLink = `/api/v1/auth/addUserKalendar/?hash=${hash}`;
    return Axios_requests("get", queryLink);
  }

  // записываем токен в куки и делаем редирект в ЛК
  login = token => {
    setCookie("token", token);
    this.setState({ isValid: true, message: "Вход выполнен!" });

    setTimeout(() => {
      this.props.getUserData().then(role => this.props.history.replace(Cabinet_Routing(role)));
    }, 2000);
  }

  render = () => (
    <Section id="confirm">
      <Card_panel>
        <Heading text="Автоматическая авторизация" />
        <div className={`confirmation ${this.state.isValid ? "confirmation--valid" : "confirmation--invalid"}`}>
          {this.state.message}
        </div>
        {!this.state.isValid && (
          <div className="bottom-links">
            <FooterLink to="/auth/" text="К авторизации" />
            <FooterLink to="/register/" text="К регистрации" />
          </div>
        )}
      </Card_panel>
    </Section>
  )
}

export default DiaryAuth;
