let menues = {
  events: { link: "/events/", text: "Мои активности" },
  groups: { link: "/groups/", text: "Мои сообщества" },
  users: { link: "/users/", text: "Мои пользователи" },
  requests: { link: "/requests/", text: "Заявки на вступление" },
  import: { link: "/import/", text: "Импорт пользователей" },
  results: { link: "/results/", text: "Мои достижения" },
  recommend: { link: "/recommend/", text: "Рекомендации" },
  reports: {link: "/reports/", text: "Мои отчеты"}
};

export let apprenticeMenu = [
  menues.events,
  menues.groups,
  menues.results,
  menues.recommend,
];

export let orgMenu = [
  menues.events,
  menues.groups,
  menues.users,
  menues.results,
  menues.requests,
  menues.reports, 
  menues.import,
];

export let groupMenu = [menues.events, menues.users, menues.results];
