import React from "react";

const ValidSVG = ({ className, style, width, height, color }) => (
  <svg className={className} style={style} width={width} height={height} viewBox="0 0 13 10" fill="none">
    <path d="M11.0624 0.338322L4.32727 7.22087L1.93761 4.5678C1.72475 4.35117 1.43605 4.22948 1.13502 4.22948C0.833997 4.22948 0.545299 4.35117 0.332441 4.5678C0.119583 4.78442 0 5.07823 0 5.38458C0 5.69093 0.119583 5.98474 0.332441 6.20136L3.52469 9.65971C3.62978 9.76754 3.7548 9.85312 3.89255 9.91153C4.0303 9.96993 4.17805 10 4.32727 10C4.4765 10 4.62425 9.96993 4.762 9.91153C4.89975 9.85312 5.02477 9.76754 5.12986 9.65971L12.6676 1.97189C12.8804 1.75526 13 1.46146 13 1.1551C13 0.848751 12.8804 0.554946 12.6676 0.338322C12.4547 0.121698 12.166 0 11.865 0C11.564 0 11.2753 0.121698 11.0624 0.338322Z" fill={color} />
  </svg>
);

ValidSVG.defaultProps = {
  className: "",
  style: {},
  width: 13,
  height: 10,
  color: "#67509c",
};

export default ValidSVG;
