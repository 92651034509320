import React from "react";
import ReactDOM from "react-dom";
import Route from "react-router-dom";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="loader-container row">
        <div className="loader-squaresContainer" />
        <div className="loader-squares">
          <div className="loader-square" />
          <div className="loader-square" />
          <div className="loader-square" />
          <div className="loader-square" />
        </div>
      </div>
    );
  }
}

export default Loader;
