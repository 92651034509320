import "./input-text.scss";

import cn from "classnames";
import React, { useRef, useState } from "react";

import ErrorSVG from "./error-svg";
import ValidSVG from "./valid-svg";

const InputText = ({
  name,
  type,
  className,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  height,
  autocomplete,
  isValid,
  isError,
  isDisabled,
}) => {
  const inputRef = useRef(null);
  const labelRef = useRef(null);
  const [isInputFocused, setFocus] = useState(false);
  const [isInputHovered, setHover] = useState(false);

  const isInputValueEmpty = !value;
  const isPlaceholderOnInput = !isInputFocused && isInputValueEmpty;

  const focused = e => {
    setFocus(true);
    if (onFocus) onFocus(e);
  };
  const unfocused = e => {
    setFocus(false);
    if (onBlur) onBlur(e);
  };
  const focusedByRef = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  };
  const hovered = () => setHover(true);
  const unhovered = () => setHover(false);

  return (
    <div
      className={cn(className, "inputTextWrapper")}
      style={{ height }}
    >
      {isDisabled ? (
        <div
          className={cn(
            "inputText",
            { isDisabled },
            { isValid },
            { isError },
            { isHover: isInputHovered },
          )}
          title={value}
        >
          {value}
        </div>
      ) : (
        <input
          ref={inputRef}
          name={name}
          type={type}
          className={cn(
            "inputText",
            { isValid },
            { isError },
            { isHover: isInputHovered },
          )}
          value={value}
          autoComplete={autocomplete}
          onChange={onChange}
          onFocus={focused}
          onBlur={unfocused}
        />
      )}

      {isValid && <ValidSVG width={16} height={16} className="inputTextIcon fadeIn" />}
      {isError && <ErrorSVG width={12} height={12} className="inputTextIcon fadeIn" />}
      <label
        ref={labelRef}
        className={cn("inputTextLabel", { isDisabled }, { toTop: !isPlaceholderOnInput })}
        onFocus={focusedByRef}
        onClick={focusedByRef}
        onMouseOver={hovered}
        onMouseOut={unhovered}
      >
        {label}
      </label>
    </div>
  );
};

InputText.defaultProps = {
  type: "text",
};

export default InputText;
