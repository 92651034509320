import React from "react";
import ReactDOM from "react-dom";

import defaultAvatar from "../../../img/default_avatar.png";
import getDomain from "../getDomain";
import Menu from "./Menu";

class User_Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.active = this.active.bind(this);
    this._handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this._handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleClickOutside, true);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        active: false,
      });
    }
  }

  active() {
    this.setState({ active: !this.state.active });
  }

  render() {
    return (
      <div
        className={`user-menu ${
          this.props.userMenuClass ? this.props.userMenuClass : ""
        } ${this.state.active ? "active" : ""}`}
        onClick={this.active}
      >
        <div
          className={`user-menu__opener ${
            this.props.menuOpenerClass ? this.props.menuOpenerClass : ""
          }`}
        >
          {this.props.photo ? (
            <img
              src={`${getDomain()}${this.props.photo}`}
              alt="user avatar"
              className="user-menu__photo"
            />
          ) : (
            <img
              src={defaultAvatar}
              alt="user avatar default"
              className="user-menu__photo user-menu__photo--default"
            />
          )}
          <div className="user-menu__name" title={this.props.name}>
            {this.props.name}
          </div>
          <i className="material-icons user-menu__icon">keyboard_arrow_down</i>
        </div>
        <Menu
          menu={this.props.menu}
          menuClass={`${this.props.menuClass ? this.props.menuClass : ""} ${
            this.props.menuOpenerClass ? this.props.menuOpenerClass : ""
          } ${this.state.active ? "active" : ""}`}
          linkClass={this.props.linkClass}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default User_Menu;
