import { Field, Form, Formik } from "formik";
import React, { useReducer } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import Btn from "../Form/Btn";
import FieldWrapper from "../Form/field-wrapper/field-wrapper";
import Form_Loader from "../Form/Form_Loader";
import FormikErrorMessage from "../Form/formik-error-message/formik-error-message";
import FormikInput from "../Form/formik-input/formik-input";
import axiosRequest from "../Global/axios-request";
import  { Card_panel }  from "../Global";
import FormsLink from "../Global/forms-link";
import { changeLocation } from "../Global/Functions";
import Heading from "../Global/Heading";
import { requestReducer } from "../Global/reducers/request-reducer";
import Section from "../Global/Section";

const EmailRestore = ({ match }) => {
  const [state, dispatch] = useReducer(requestReducer.reducer, requestReducer.initialState);

  const request = async key => {
    // @ts-ignore
    dispatch({ type: "loading" });

    try {
      const response = await axiosRequest("post", "/api/v1/email/restore/", { key });

      // @ts-ignore
      dispatch({ type: "success", message: response.data.data.message });
    } catch (error) {
      // @ts-ignore
      dispatch({ type: "failure", message: "Неверный код смены почты" });
    }

    setTimeout(() => {
      changeLocation("/auth");
    }, 2000);
  };

  const onSubmit = (values, { resetForm }) => {
    request(values.token);
    resetForm();
  };

  const { token } = match.params;

  const initialValues = {
    token: token || "",
  };

  const validationSchema = Yup.object().shape({
    token: Yup.string()
      .required("Поле обязательно для заполнения"),
  });

  return (
    <Section id="confirm">
      <Card_panel>
        <Heading text="Восстановление почты" />
        <Formik {...{ initialValues, validationSchema, onSubmit }}>
          <Form>
            <FieldWrapper
              field={
                <Field
                  name="token"
                  label="Введите Ваш token восстановления email"
                  component={FormikInput}
                />
              }
              error={<FormikErrorMessage name="token" />}
            />
            <div className="bottom-links">
              <div className="bottom-links__wrapper">
                <FormsLink to="/">На главную</FormsLink>
              </div>
              <Btn
                  type="submit"
                  text="Восстановить пароль"
                  className="btn btn--standart"
                />
            </div>
          </Form>
        </Formik>
      </Card_panel>
      <Form_Loader
          status={state.loaderStatus}
          message={state.message}
        />
        <Link
            to="/register/support/"
            className="btn btn--filled-white btn--fixed"
          >
            Обратиться в техподдержку
          </Link>
    </Section>
  );
};

export default withRouter(EmailRestore);
