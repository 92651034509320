import React from "react";
import cn from "classnames";

import Menu from "../Global/Menu/Menu";
import Footer from "../Index/Footer";
import Header from "../Index/Header";
import { apprenticeMenu, groupMenu, orgMenu } from "../Global/Menu/MenuesList";

class Page_wrapper extends React.Component {
  state = {
    isCabinet: false,
    hideHeaderFooter: false,
    sideMenu: [],
  };

  componentDidMount = () => {
    if (this.props.userData) {
      const role = Object.keys(this.props.userData.roles)[0];
      this.getMenu(role);
    }

    this.formState();
  }

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props) {
      this.formState();
    }

    if (prevProps.userData !== this.props.userData) {
      const role = Object.keys(this.props.userData.roles)[0];
      this.getMenu(role);
    }
  }

  formState = () => {
    const isCabinet = this.checkUrl("/student/", "/org/", "/group/");
    const hideHeaderFooter = this.checkUrl("/auth/", "/register/");

    this.setState({ isCabinet, hideHeaderFooter });
  }

  getMenu = role => {
    let sideMenu = [];

    switch (Number(role)) {
      case 5: {
        sideMenu = apprenticeMenu.map(item => ({ ...item, link: `/student${item.link}` }));
        break;
      }
      case 7: {
        sideMenu = orgMenu.map(item => ({ ...item, link: `/org${item.link}` }));
        break;
      }
      case 8: {
        sideMenu = groupMenu.map(item => ({ ...item, link: `/group${item.link}` }));
        break;
      }
    }

    this.setState({ sideMenu });
  }

  checkUrl = (...paths) => paths.some(path => window.location.pathname.includes(path));

  render() {
    const { userData = {}, history, children } = this.props;
    const { isCabinet, hideHeaderFooter, sideMenu } = this.state;

    return (
      <div className="page-wrapper">
        <div className="without-footer">
          {!hideHeaderFooter && <Header history={history} data={userData} className={cn({ "header--main-page": !isCabinet })} />}
          <main className="main-block">
            {isCabinet
              ?
                <div className="container">
                  <div className="row">
                    <aside className={"col-xs-12 col-sm-12 col-md-3 col-lg-3 sidebar sidebar--left"}>
                      {Boolean(sideMenu.length) &&
                        <Menu
                          history={history}
                          menu={sideMenu}
                          menuClass="sidebar__menu"
                          linkClass="sidebar__link"
                        />
                      }
                    </aside>
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                      {children}
                    </div>
                  </div>
                </div>
              :
                children
            }
          </main>
        </div>
        {!hideHeaderFooter && <Footer />}
      </div>
    );
  }
}

export default Page_wrapper;
