import axios from "axios";

import { history } from "../../App";
import { deleteCookie, getCookie } from "./Cookie_function";
import getDomain from "./getDomain.js";

const errorsHandler = promise =>
  promise.then(null, error => {
    const { response: { status } } = error;

    switch (status) {
      case 401: {
        deleteCookie("token");
        const notAuthPage = history.location.pathname !== "/auth/";
        notAuthPage && history.push("/auth/");
        return promise;
      }

      default:
        throw error;
    }
  });

// eslint-disable-next-line camelcase
const axiosRequest = (method, url, data, DEL_THIS_LEGACY_PROPERTY_token, additionalHeader) => {
  const domain = getDomain();
  domain && (axios.defaults.baseURL = domain);

  const token = getCookie("token");

  token ? (axios.defaults.headers["x-token"] = token) : (delete axios.defaults.headers["x-token"]);

  additionalHeader && (axios.defaults.headers[additionalHeader.type] = additionalHeader.text);

  const getRequestPromise = {
    get: () => data ? axios.get(url, { params: data }) : axios.get(url),
    post: () => axios.post(url, { data }),
    postData: () => axios.post(url, data),
    put: () => axios.put(url, { data }),
    getFile: () => data ? axios.get(url, { params: data, responseType: "blob" }) : axios.get(url, { responseType: "blob" }),
    delete: () => axios.delete(url, { data: { data } }),
  }[method];

  return errorsHandler(getRequestPromise());
};

export default axiosRequest;
