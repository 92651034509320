import React from "react";

import Heading from "./Heading";

class Section extends React.Component {
  render() {
    return (
      <section id={this.props.id} className={this.props.sectionClassName}>
        <div className={"container"}>
          {this.props.heading !== undefined ? (
            <Heading text={this.props.heading} />
          ) : (
            ""
          )}
          <div
            className={
              this.props.className !== undefined ? this.props.className : ""
            }
          >
            {this.props.children}
          </div>
        </div>
      </section>
    );
  }
}

export default Section;
